/* Cuescore matches */
.cs-match {
    margin-bottom: -4px;
    border-bottom: 4px solid #00000033;
    margin-bottom: 10px;
  }
  
  .cs-match :hover {
    background-color: #ddd;
  }
  
  .cs-match-no {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #aaaaaa;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 8px;
    margin-bottom: -4px;
    border-bottom: 4px solid #00000033;
    width: 32px;
    text-align: center;
  }
  
  .cs-match-time {
    display: flex;
    line-height: 1em;
    padding: 3px;
    background-color: #158cba;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    margin-bottom: -4px;
    border-bottom: 4px solid #00000033;
    flex-direction: column;
    justify-content: center;
    width: 50px;
  }
  
  .cs-match-players {
    display: flex;
    flex-grow: 1;
    align-items: center;
    /* align vertical */
    padding: 0px 10px;
  }
  
  .tournament-admin-container {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: #158cba 2px solid;
  }
@import './css/livescore.css';
@import './css/cuescore.css';
@import './css/fonts.css';

.App {
  text-align: center;

}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 50s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
  z-index: 1125;
}
.modal-backdrop.show:nth-last-child(2){
  z-index: 1100;
}
div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
  z-index: 1075;
}
.modal-backdrop.show:nth-last-child(4){
  z-index: 1050;
}
div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
  z-index: 1025;
}
.modal-backdrop.show:nth-last-child(6){
  z-index: 1000;
}

.layout-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  height: 100px;
  background-color: #555555;
  margin-top: 20px;
  color: white;
}

.copyright {
  margin-top: 40px;
  text-align: center;
}

.copyright a {
  color: #61dafb;
}

.copyright a:hover {
  color: aquamarine;

}

/* Small */
.small-color-picker .react-colorful {
  width: 120px;
  height: 120px;
  position: absolute;
  z-index: 1000;
}
.small-color-picker .react-colorful__hue {
  height: 20px;
}

.color-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;  
  margin: 5px 0px;
}

.color-selector-container label {
  width: 57px;
  font-weight: bold;
}
.color-selector-container input {
  width: 70px;  
  margin: 0px 20px;
  font-family: 'Consolas', monospace;
}

.font-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  align-content: flex-start;
  flex-wrap: wrap;  
  margin: 5px 0px;
}

.font-selector-container label {
  
  font-weight: bold;
}

.font-selector-container select {
  width: 90px;
  margin: 0px 5px;
}

.font-selector-container input {
  width: 40px;
  margin: 0px 5px;
}

.composite-selector-container {
  border-bottom: 1px solid rgb(0, 152, 179);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.composite-selector-container h1 {
  text-transform: uppercase;
  color: rgb(0, 152, 179);
  font-size: 18px;
  font-weight: 900;
  border-bottom: 1px solid rgb(0, 152, 179);
  text-align: center;
  margin-bottom: 10px;
}

.custom-layout-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

/* Pricing list */

/* section.pricing {
  background: #007bff;
  background: linear-gradient(to right, #0062E6, #33AEFF);
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
} */

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .pricing .card:hover .btn {
    opacity: 1;
  }
}

/* End pricing list */

.download-step {
  margin-bottom: 2em;
}

.header {
  margin-top: 1em;
  margin-bottom: 1em;
  border-bottom: rgb(0, 152, 179) 2px solid;
}

.header h1 {
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  color: rgb(0, 152, 179);
}

.header h2 {
  font-size: 24px;
  font-weight: 400;
  color: rgb(0, 152, 179);
}

.header-h3 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgb(0, 152, 179);
}

.zoomed {
  transform: scale(0.58);
  transform-origin: top left;
  width: 920px;
  background-color: #282c34;
  padding: 5px;
}

.stream-modal {
  width: 40vw !important;
  max-width: 40vw !important;
  min-width: 760px !important;
}

.stream-preview-changes-modal {
  width: 800px !important;
  min-width: 820px !important;
}

.stream-preview-changes-arrow {
  margin-left: 312px;
  margin-bottom: 0px;
}

.stream-preview {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 1em;
  margin-bottom: 20px;
}

.board-background {
  background-color: rgb(0, 152, 179);
}

.code-format {
  font-family: 'Courier New', Courier, monospace;
  background-color: #eeeeee;
  border: 1px solid rgb(204, 109, 109);
  border-radius: 3px;
  padding: 1px 4px;
  margin-left: 5px;
}

.current-match {
  text-transform: uppercase;
  font-weight: bold;
  color: #888;
}



.pack-container {
  border: 1px solid #2f4164;
  cursor: pointer;
}

.input-validation {
  height: 0px;
  font-size: 12px;
  margin-top: 2px;
  padding-bottom: 2px;
}

.big-number {
  font-weight: bolder;
  font-family: Verdana, Geneva, Tahoma, sans-serif
}

.price-cell {
  border: #aaaaaa 1px solid;
  border-bottom: #aaaaaa 10px solid;
  border-radius: 10px;
  margin-top: 1em;
  font-size: 16px;
}

.price-cell-selected {
  border: rgb(0, 152, 179) 2px solid;
  border-bottom: rgb(0, 152, 179) 10px solid;
  border-radius: 10px;
  margin-top: 1em;
  font-size: 16px;
}

.price-cell h1 {
  text-align: center;
  width: 100%;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  color: rgb(0, 152, 179);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.price-cell ul {
  list-style: none;
}

.price-cell ul li:before {
  content: '✓';
}

.price-cell li span {
  margin-left: 30px;
}

.price-cell-middle {
  border-top: #aaaaaa 1px solid;
  ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.price-cell-features {
  border-top: #aaaaaa 1px solid;
  ;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 20px 20px 4px 20px;
}

.price-large-text {
  font-size: 40px;
  font-weight: 900;
  margin-bottom: -10px;
}

.currency-label {
  padding: 2px 10px;
  font-size: 18px;
}

.youtube-embed {
  width: 100%;
  height: 410px;
}

.promotion {
  font-size: 18px;
}

.promotion li {
  margin-bottom: 14px;
}

.promotion ul {
  list-style: square;
}


.promotion-row {
  margin-top: 30px;
  margin-bottom: 50px;
}

/* Match reports */
.match-report {
  /* background-color: #f3f3f3; */
  /* width: 460px; */
  margin-bottom: -4px;
  border-bottom: 4px solid #00000033;

  background-color: #eeeded;
}

.match-report-container {
  display: flex;
  margin-bottom: 20px;
}

.cs-match-no a {
  color: white;
}

.match-report .title {
  display: flex;
  font-size: 14px;
}

.match-report .title .name {
  width: 180px;
  text-align: center;
}

.match-report .title .name.mobile {
  width: 125px;
  text-align: center;
}


.match-report .title .final-score {
  width: 34px;
  text-align: center;
  font-weight: bold;
}

.match-report .frame-area {
  display: flex;
}

.match-report .frame-report {
  border-top: 1px black solid;
  display: flex;
}

.match-report .frame-report .breaks {
  width: 180px;
  text-align: center;
}

.match-report .frame-report .breaks.mobile {
  width: 125px;
  text-align: center;
}

.match-report .frame-report .score {
  width: 51px;
  text-align: center;
}

.match-report .frame-report .score.won {
  font-weight: bold;
  color: green;
}

.match-report .frame-report .score.lost {
  font-weight: bold;
  color: #ba0000;
}

.match-report .frame-report .divider {
  border-right: 1px solid black;
}

.match-report .bottom {
  display: flex;
}

.match-report .bottom .text {
  width: 180px;
  text-align: center;
}

.round-name {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
}

.match-report .bottom .text.mobile {
  width: 125px;
  text-align: center;
}

.match-report .bottom .details {
  width: 100px;
  text-align: center;
  font-weight: bold;
}

.empty-match-no {
  width: 32px;
}

.custom-livescore-container {
    width: 100%;
    height: 50px;
    background-color: #2B66F2;
    display: flex;
    justify-content: space-between;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    color: white;
  }
  
  .upper-case {
    text-transform: uppercase;
  }
  
  .custom-livescore-player {
    background-color: white;
    color: black;
    flex-grow: 1;
    flex-basis: 0;
    border-bottom: 6px solid rgba(0, 0, 0, 0.274);
    align-items: center;
    display: flex;
    font-size: 26px;
    justify-content: space-between;
  }
  
  .custom-livescore-framescore {
    width: 200px;
    border-bottom: 6px solid rgba(0, 0, 0, 0.274);
    display: flex;
    justify-content: space-around;
    align-items: center;  
  }
  
  .custom-livescore-player-frames {
    width: 65px;
    text-align: center;
  }
  
  .custom-livescore-distance {
    width: 70px;
    text-align: center;
  }
  
  .custom-livescore-playername {
    display: flex;
    align-items: center;
  }
  
  .custom-livescore-score {
    width: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    background-color: white;
    color: black;
    border-bottom: 6px solid rgba(0, 0, 0, 0.274);
    display: flex;
    justify-content: space-around;
    align-items: center;  
  }
  
  .custom-livescore-active {
    background-color: #2B66F2;
    height: 14px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 14px;
    margin: 0px 15px;
  }
  
  .custom-livescore-inactive {
    width: 44px;
  }
  
  .custom-powered-label {
    margin-top: -1px;
    background-color: #2f4164;
    font-size: 18px;
    color: white;
    width: 300px;
    border-radius: 0px 0px 10px 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
  }

  
/* Livescore styling */
.livescore-outer-container {
    margin: 30px 0px;
    width: 100%;
  }
  
  .livescore-container {
    background-color: #eeeeee;
    border: 1px solid black;
    display: flex;
    flex-direction: row;
  }
  
  .livescore-container-mobile {
    background-color: #ddd;
    /* border: 1px solid black; */
    /* display: flex; */
    flex-direction: column;
  }
  
  .livescore-player {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 0;
  }
  
  .livescore-left {
    justify-content: flex-end;
  }
  
  .livescore-right {
    justify-content: flex-start;
  }
  
  .livescore-score {
    font-weight: bold;
    min-width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    text-align: center;
  }
  
  .livescore-dark {
    background-color: #2f4164;
    color: white;
  }
  
  .livescore-tablename {
    font-weight: bold;
  }
  
  .livescore-active {
    background-color: #2f4164;
    height: 10px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 10px;
    margin: 0px 5px;
  }
  
  
  .frame-score {
    text-align: center;
    min-width: 50px;
    padding: 8px 18px 8px 18px;
  }
  
  .balls-container {
    background-color: #2f4164;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: white;
    font-size: 16px;
    align-items: center;
    font-weight: bold;
  }
  
  .ball {
    background-color: red;
    height: 14px;
    border: 1px solid white;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 14px;
    align-self: center;
    margin: 5px;
  }
  
  .ball-1 {
    background-color: rgb(255, 101, 101);
  }
  
  .ball-2 {
    background-color: rgb(243, 243, 124);
  }
  
  .ball-3 {
    background-color: rgb(122, 245, 122);
  }
  
  .ball-4 {
    background-color: rgb(233, 152, 45);
  }
  
  .ball-5 {
    background-color: rgb(0, 174, 255);
  }
  
  .ball-6 {
    background-color: rgb(238, 74, 224);
  }
  
  .ball-7 {
    background-color: rgb(58, 58, 58);
  }
  
  .center-cell {
    text-align: center;
  }
  
  .powered-label {
    margin-top: -1px;
    background-color: #2f4164;
    font-size: 18px;
    color: white;
    width: 300px;
    padding-bottom: 3px;
    padding-top: 2px;
    border-radius: 0px 0px 10px 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
  }
  